<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getPLUInventoryReport"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-10 col-sm-9 col-8">
              <h2 class=" font-weight-normal">
                {{ $t('management.txtreportByPLU') }}
              </h2>
              <show-date></show-date>
            </div>
            <div
              v-if="isExport"
              class="col-md-2 col-sm-3  col-4 text-right text-success"
            >
              <CButton
                id="PLUInventoryExportButton"
                v-on:click="exportPLUInventoryReport()"
                block
                color="info"
              >
              <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
            </CButton>
            </div>
          </div>

          <search-pattern
            class="mb-4"
            :keyword="keyword"
            :searchBy="searchBy"
            v-on:getSort="setSearch"
          ></search-pattern>
          <CRow>
            <CCol sm="12" lg="12">
              <sortdata
                v-on:getSort="setSort"
                :sort_value="sort_value"
                :sortdata="sortdata"
              />
              <hr />
              <DataTable
                id="PLUInventoryDatatable"
                :items="items"
                :fields="fields"
                hover
                border
                style="white-space:nowrap;"
              >
              <template #onhandQty="{ item, index }">
                  <td :id="'PLUInventoryItemonhand'+index" v-if="floatValue(item.onhandQty) < 0.00" class="text-right font-weight-normal text-danger">{{ item.onhandQty }}</td>
                  <td :id="'PLUInventoryItemonhand'+index" v-else class="text-right font-weight-normal text-dark">{{ item.onhandQty }}</td>
              </template>
              </DataTable>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getPLUInventoryReport">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import Sortdata from '@/containers/SortData'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'
import SearchPattern from '@/containers/SearchPattern'
import util from '@/util/util'

export default {
  components: {
    Pagination,
    Sortdata,
    DataTable,
    SearchPattern,
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
      searchLoadingButton: true,
      keyword: '',
      searchBy: '',
      itemList: [],
      apiLoaded: false,
      sortdata: 'name',
      skuType: '',
      lastupdate: '',
      sort_value: {
        name: 'name',
        category: 'ProductPLUStock.categoryName',
        onhandQtyMin: 'onhandQtyMin',
        onhandQtyMax: 'onhandQtyMax',
        PLUCode: 'PLUCode',
      },
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    isPermission() {
      return permis.findPermissionRead('inventory', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('inventory', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        {
          key: 'id',
          label: this.$i18n.t('#'),
          _classes: 'text-center font-weight-normal text-dark',
          _style: 'width:2%',
        },
        {
          key: 'productSKUId',
          label: this.$i18n.t('pluCode'),
          _style: 'width:15%',
          _classes: 'font-weight-normal text-dark',
        },
        {
          key: 'name',
          label: this.$i18n.t('productName'),
          _style: 'width:40%',
          _classes: 'font-weight-normal text-dark',
        },
        { key: 'unit', label: this.$i18n.t('unit'), _classes: 'text-left font-weight-normal text-dark' },
        { key: 'categoryName', label: this.$i18n.t('category'), _classes: 'font-weight-normal text-dark', },
        {
          key: 'onhandQty',
          label: this.$i18n.t('onhandQty'),
          _classes: 'text-right font-weight-normal text-dark',
        },
      ]
    },
    items() {
      const data = this.data
      let detail = []
      const currentPage = (this.meta_data.current_page || 1) - 1
      const limit = this.meta_data.limit || 50
      for (let i = 0; i < data.length; i++) {
          let item = data[i]

          // Parse data Category Name
          const category = item.Category || null
          const categoryName = category.name || 'N/A' 

            // Parse unit data.
          const unit = item.unit || {}
          const unitName = unit.name || 'N/A'

          // Parse PLU Code
          const PLUCode = item.PLUCode || ''

          // PLU Name
          const name = item.name || ''

          const SKURatio = item.SKURatio || 1

          let onhandQty = ''
          if (item.skuType === 'SV' || item.skuType === 'BOM') {
            // Product Type checked.
            onhandQty = 'non-stock'
          } else {
            const productPLUStock = item.ProductPLUStock || null
            if (productPLUStock == null) {
              onhandQty = 'No Stock Data'
            } else {
              // Not existing Product SKU Stock
              onhandQty = util.convertOnhand(productPLUStock.onhandQty || 0)
            }
          }
          
          detail.push({
            id: (currentPage * limit) + (i + 1),
            productSKUId: PLUCode,
            name: name,
            unit: unitName + ' / ' + SKURatio,
            categoryName: categoryName,
            onhandQty: onhandQty,
          })
      }
      return detail
    },
    sortAttr: {
      get() {
        let sortdata = this.sortdata
        if (sortdata == 'sortByNumMin' || sortdata == 'sortByNumMax') {
          sortdata = 'ProductSKU.SKUPrice'
        }

        if (sortdata == 'onhandQtyMin' || sortdata == 'onhandQtyMax') {
          sortdata = 'ProductPLUStock.onhandQty'
        }
        return sortdata
      },
      set(newValue) {
        return newValue
      },
    },
  },
  created() {
    this.getPLUInventoryReport()
  },
  methods: {
    setSearch(searchBy, keyword) {
      this.keyword = keyword
      this.searchBy = searchBy
      this.getPLUInventoryReport()
    },
    setSort(value) {
      this.sortdata = value
      this.getPLUInventoryReport()
    },
    getPLUInventoryReport(page = 1) {
      this.loadingButton = false
      this.searchLoadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const headers = { shopObjectId: shopObjectId }

      let sortOrder = ''
      if (this.sortdata == 'sortByNumMin' || this.sortdata == 'onhandQtyMin') {
        sortOrder = 'asc'
      } else if (
        this.sortdata == 'sortByNumMax' ||
        this.sortdata == 'onhandQtyMax' ||
        this.sortdata == 'PLUCode'
      ) {
        sortOrder = 'desc'
      } else {
        sortOrder = 'asc'
      }

      let params = {
          shopObjectId: shopObjectId,
          page: page,
          limit: 50,
          sortattr: this.sortAttr,
          sortorder: sortOrder,
      }

      // Search...
      if (this.searchBy !== '') {
        params['searchVal'] = this.keyword
        params['searchAttr'] = this.searchBy
      } 

      axios({
        url: '/inventory/v2.0/' + uid + '/productplustock',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.searchLoadingButton = true
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportPLUInventoryReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const params = { shopObjectId: shopObjectId }
      const headers = { shopObjectId: shopObjectId }

      report({
        url: '/inventory/v1.0/' + uid + '/productplustock/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานสินค้าคงเหลือตามขนาดบรรจุ.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    floatValue(value) {
      return parseFloat(value)
    }
  },
}
</script>
